import React from 'react'

import './index.css'

function About({colorClasses}) {

    return (<>
         <div id="about" className={colorClasses}>
                  <div className="flex  flex-col  home-company-info  w-full pb-5 text-white items-center md:items-start text-center md:text-left mb-5 text-sm md:text-lg">
                      <div className="flex">
                        <p className="max-w-xs sm:max-w-sm md:max-w-xl lg:max-w-4xl mb-8 mt-8">
                          <span className="text-md">Meditex Healthcare Hub Limited (<em>incorporated 2021</em>)</span> is a quality-driven medical diagnositic center, which
                          provides you with a health care experience that you can trust. With a focus on delivering the best to our
                          patients, we employ the best hands to get the work done.
                        </p>
                      </div>
                      <div className="flex content-start text-sm mb-4">
                        <div className="flex flex-col items-left ">
                          <p className="text-bold">Opening Hours</p>
                          <p>Monday - Friday: 8am - 6pm</p>
                          <p>Saturday & Public Holidays: 8am - 6pm</p>
                          <p>Sunday: Closed</p>
                          <p>NB: Outside our working hours expect prompt response in an Emergency</p>
                        </div>
                      </div>
                  </div>
                </div>

      </>
    );
}

export default About