import React from 'react'

import './index.css'

function Footer(props) {
    return <>
        <div className="w-full  footer-bg h:20 lg:h-10  text-white text-xs">
            <div className="flex flex-col sm:flex-row justify-center p-2">
                <span>Copyright &copy; 2021</span>
                &nbsp;
                <span className="capitalize"> |MEDITEX HEALTHCARE HUB LIMITED</span>
                &nbsp;
                <span>- All rights reserved</span>
                &nbsp;
                <span> Read our Privacy Policy, designed by <span className="footer-designed-by">Nwosu Onyedikachi</span></span>
            </div>
        </div>
    </>
}

export default Footer