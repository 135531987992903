import React from 'react'

function Login() {
    const handleSubmit = (e)=>{
        e.preventDefault();
    }
    return (
        <>
            <div className="flex">
                <div className="flex-1">  
                    <p>Testing with Accuracy and precision</p>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum optio vel nostrum, modi itaque rerum minus culpa excepturi nisi fugit! Error laboriosam unde ad quibusdam deserunt quis ipsa, blanditiis maxime.</p>

                </div>
                <div className="flex-1 justify-items-center h-full content-cemter">
                    <h1>Sign In</h1>
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <label htmlFor="email"> email</label>
                        <input type="email" name="email" id="email" />

                        <label htmlFor="password">password</label>
                        <input type="password" name="password" id="password" />

                        <button type="submit"></button>
                    </form>    
                </div>
                
            </div>

        </>
    )
}

export default Login
