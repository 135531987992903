import React from 'react'

import './index.css'

export  const TopBar= (props)=> {
    return <>
        <div className="hidden md:flex topBar-bg text-white font-serif bg-gray-900 ">
            <div className="mr-auto ml-2 sm:ml-16 text-left ">
                <i className="fa fa-volume-control-phone text-white"></i>
                <span>+2348139203920 | +2347035104804 | +2347065048160</span>
                &nbsp;
                <span>|MEDITEX HEALTHCARE HUB|</span>
                &nbsp;
                <i className="fa fa-envelope text-white"></i>
                <span> meditexdiagnosticlaboratory@gmail.com</span>
            </div>
            <div className="mr-16 hidden">
                <span>Member Login</span>
                <span>Check Result</span>
                <span>List of Tests</span>
            </div>
        </div>
    </>
}
