import React from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as RCarousel } from 'react-responsive-carousel';


import {About, Contact } from '..'
import Footer from '../Footer'
import ServiceCard from '../ServiceCard'

import './index.css'

const images = [
    {
      url: 'https://res.cloudinary.com/noi/image/upload/v1627435203/meditex/slider-01.jpg', 
      message: "Quality is our Focus"
    },
    {
      url: 'https://res.cloudinary.com/noi/image/upload/v1627593226/meditex/slider-02.jpg',
      message: "Delivering the best"
    },
    {
      url: 'https://res.cloudinary.com/noi/image/upload/v1628058167/meditex/slide-06.jpg',
      message: '... patient first'
    }
  ]

const services = [
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041473/meditex/coporate-bp.jpg',
    name: 'Medical Health Checks',
    description: `This includes Annual comprehensive,checkup Pre-employment Medical Examination,
    Food Handlers Test,
    Pre-nuptial Health Screening,
    Pre-enrolment Medical Test for Schools,
    Well Woman Health Check,
    Domestic Staff Screening`,
    tags: [
      "checkup",
      'fitness',
      'health screening'
    ]

  },
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041478/meditex/Blood-Haematology.jpg',
    name: 'Blood Banking',
    description: `They include but not limited to these:
    Blood Banking and Serology services(BBS), Blood donation
    `
    ,
    tags: [
      "Blood",
      'Bank',
      'Lagos State Government Approved'
    ]

  },
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627435203/meditex/slider-01.jpg',
    name: 'Medical Lab Tests',
    description: `We ensure global best practice delivery of medical laboratory services in Nigeria, 
    providing wide range of diagnostic services including blood, body fluids and tissue 
    pathology in Heamatology, clinical chemistry, microbiology,  etc.`,
    tags: [
      "endocrinology",
      'virology',
      'parasitology'
    ]

  },
  // {
  //   imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041472/meditex/Clinical-Chemistry.jpg',
  //   name: 'CHEMICAL PATHOLOGY',
  //   description: `They include but not limited to these:

  //   Kidney Function Test (EUCr)
  //   Cardiac markers
  //   Liver function Test
  //   Lipid Profile
  //   Diabetic Profile
  //   Blood Minerals
  //   Blood Disorders
  //   Bleeding Time
  //   Blood Glucose (Blood Sugar)
  //   Total Cholesterol
  //   C-reactive protein
  //   Uric acid
  //   Glycated Haemoglobin`,
  //   tags: [
  //     "Glucose",
  //     'LFT',
  //     'Lipid Profile'
  //   ]

  // },
  // {
  //   imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041473/meditex/Microbiology.jpg',
  //   name: 'Microbiology',
  //   description: `They include but not limited to these:

  //   Aspirate culture & sensitivity
  //   Tb Ag and AFB
  //   HVS culture & sensitivity
  //   HVS routine examination
  //   Blood culture & sensitivity
  //   Cholera ag
  //   Chlamydia
  //   Urine culture & sensitivity
  //   Wound swab  culture & sensitivity
  //   CSF- culture & sensitivity
  //   Ear swab culture & sensitivity
  //   Endocervical swab
  //   Eye swab culture & sensitivity
  //   Faecal occult blood
  //   Nasal swab culture & sensitivity
  //   Pleural aspirate culture & sensitivity
  //   Pus culture & sensitivity`,
  //   tags: [
  //     'culture',
  //     'sensitivity',
  //     'swab'
  //   ]

  // },
  // {
  //   imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041478/meditex/endocrinology.jpg',
  //   name: 'Endocrinology',
  //   description: `They include but not limited to these:

  //   Hormonal imbalance
  //   Female hormonal profile
  //   Male hormonal profile
  //   Thyroid function tests
  //   Growth hormones
  //   Ovulation tests
  //   HCG`,
  //   tags: [
  //     "hormonal",
  //     'profile',
  //     'thyroid test'
  //   ]

  // },
  // {
  //   imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041477/meditex/virology.jpg',
  //   name: 'Virology / Immunology',
  //   description: `They include but not limited to these:
  //   Hepatitis B sAg, A, C
  //   Hepatitis B profile
  //   Human Immunodeficiency Virus (HIV I & II)
  //   Hepatitis C
  //   Hepatitis A Ag
  //   Veneral Disease Research Lab`,
  //   tags: [
  //     "HBsAg",
  //     'HIV',
  //     'Hepatitis A, B and C'
  //   ]

  // },
  
  // {
  //   imgURL: 'https://res.cloudinary.com/noi/image/upload/v1627041474/meditex/Parasitology-2.jpg',
  //   name: 'Parasitology',
  //   description: `They include but not limited to these:

  //   Malaria parasitemia
  //   Blood Microfilarial worms
  //   Stool Analysis
  //   Urine Analysis`,
  //   tags: [
  //     'MP',
  //     'Widal',
  //     'Urinalysis'
  //   ]
  // }
]


const accreditations = [
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1629104367/meditex/mlscn.png',
    name: 'MLSCN',
    description: ``
  },
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1629104367/meditex/cac.png  ',
    name: 'CAC',
    description: ``
  },
  {
    imgURL: 'https://res.cloudinary.com/noi/image/upload/v1629104367/meditex/hefamaa.png',
    name: 'HEFAMAA',
    description: ``
  },
]

const partners = [
  'Jobi Clinic & Maternity Hospital',
  'Triumph Hospital',
  'Winners Medical Centre',
  'Ire-Anu',
  'JayKay Hospital',
  'Empire Hospital',
  'Elika Clinic',
  'B and T hospital',
  'Owutu Clinic'
]
class Home extends React.Component {

    render(){
        return (
            <>
                <b className="screen-overlay"></b>
                 <div className="carousel-bg sm:pt-2 md:hidden">
                  <RCarousel infiniteLoop useKeyboardArrows autoPlay>
                      {images.map((image)=>(
                        <div key={image.url}>
                          <img src={image.url} alt={image.message} />
                          <p className="legend">{image.message}</p>
                      </div>
                      ))}
                  </RCarousel>
                </div> 
                <div className="header-image">
                  <>
                    <About colorClasses="text-white"/>
                  </>
                </div>
                <div className="bg-white text-purple"  id="accreditations">
                   <p className="text-2xl font-bold capitalize  text-left sm:text-center ml-10 sm:ml-0">Accreditations</p>
                   <p className="py-4 max-w-xs sm:max-w-sm md:max-w-xl text-center sm:text-justify  lg:max-w-4xl mx-auto">
                    The Meditex Healthcare Hub Limited is fully registered with the
                    Corporate Affairs Commission and the Medical Laboratory Science Council of Nigeria (MLSCN). We are also
                    accredited by the Lagos State Health Facility Monitoring and Accreditation Agency (HEFAMAA) 
                    as a secondary healthcare provider.
                   </p>
                   <div className="py-8">
                        <div className="flex justify-center">
                          { accreditations.map((accred,i)=>(
                              <div key={i}>
                                  <img src={accred.imgURL} alt={accred.name}/>
                              </div>
                          ))}
                        </div>
                   </div>
                </div>
                <div className="sm:mb-2" id="services">
                  <div className="text-purple text-2xl font-bold capitalize ml-10 sm:ml-0  text-left sm:text-center ">Our Services</div>
                </div>
                <div className="p-10 grid grid-cols-1 sm:grid-cols-3 gap-5">
                  {services.map((service, i) => (
                    <ServiceCard key={service.name} service={service}/>
                  ))}
                </div>
                <Contact/>
                <Footer/>
            </>
        )
    }
}


export default Home