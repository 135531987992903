
import './App.css';
import { 
  TopBar,
  NavBar,
  Home,
  Login,
  NotFound
 } from './components';

import {BrowserRouter as Router,Routes as Switch, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <TopBar/>
        <NavBar/>
        <Switch>
          {/* <Route path='/login'  element={<Login />}/> */}
          <Route exact path="/" element={<Home />}/>
          <Route path='*' component={NotFound}/>
        </Switch>
       
      </Router>

    </div>
  );
}

export default App;
