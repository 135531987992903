import React, {useState} from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";

import './index.css'

function Contact(props){

  const recaptchaRef = React.createRef();


  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [values, setValues] = useState({
    name: '',
    phone: '',
    subject: '',
    message: '',
    email: ''
  })
  const handleNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      name: event.target.value,
    }));
  };
  const handleSubjectInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      subject: event.target.value,
    }));
  };
  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };
  const handlePhoneInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      phone: event.target.value,
    }));
  };
  const handleMessageInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      message: event.target.value,
    }));
  };

  const onChange = () => {
    handleSubmit()
  }

  const handleSubmit = async() => {
    if(values.name && values.phone && values.email && values.message) {
      setValid(true);
  }
  const notify = (message) => toast.success(`${message}`);
    try {
      await axios.request({
        baseURL: 'https://meditex-email-service.herokuapp.com',
        method: 'post',
        data: {
          from: 'meditexdiagnosticlaboratory@gmail.com',
          to: 'meditexdiagnosticlaboratory@gmail.com',
          subject: values.subject,
          message: values.message,
          phone:values.phone,
          email: values.email 
        }
      })
      setSubmitted(true)
      setValues({
        name: '',
        phone: '',
        subject: '',
        message: '',
        email: ''
      });
      notify("We would respond within 24 hours");
    } catch (error) {
      notify("Pls try again later");
      setValues({
        name: '',
        phone: '',
        subject: '',
        message: '',
        email: ''
      });
    }
  };

    return (<>
        <div id="contact" className=" w-full text-left sm:text-center  pl-8 text-purple text-2xl font-bold capitalize">Contact US</div>
        <div className="flex flex-col contact-bg bg-gray-100 sm:flex-row">
          <form action="#" className="w-full md:w-3/4 lg:w-3/6 p-4" onSubmit={(e) => { e.preventDefault(); recaptchaRef.current.execute(); }}>
            <div className="p-2">
              <input 
              className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-2 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300" 
              type="text"
              placeholder="Name" 
              required
              value={values.name}
              onChange={handleNameInputChange} />
            </div>
            <div className="p-2">
              <input 
              className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-2 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300" 
              type="email" 
              placeholder="Email" 
              required 
              value={values.email}
              onChange={handleEmailInputChange}/>
            </div>
            <div className="p-2">
              <input 
              className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-2 px-2 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300" 
              type="number" 
              placeholder="Mobile Number" 
              required
              value={values.phone} 
              onChange={handlePhoneInputChange}/>
            </div>
            <div className="p-2">
              <input
              className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-2 px-2 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300" 
              type="text" 
              placeholder="Subject" 
              required 
              value={values.subject}
              onChange={handleSubjectInputChange}/>
            </div>
            <div className="p-2">
              <textarea 
              className="resize-none border rounded-md block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-2 px-3 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300 h-40" 
              placeholder="Message" 
              required
              value={values.message}
              onChange={handleMessageInputChange}></textarea>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6Ld92gEcAAAAAChdBIpw4Yycp0V11RPPD8WTmUk2"
              onChange={onChange}
            />
            <div className="p-2 pt-3">
              <button className="w-full text-white light-purple-bg hover:purple-bg font-bold py-2  px-2 rounded text-xl">
              Send
              </button>
            </div>
          </form>
          <div className="flex flex-col text-left justify-start text-light-blue pl-4 pr-4 pt-0 pb-4 sm:p-4 text-justify">
            {/* <h3 className="p-2 text-3xl">Contact info</h3> */}
            <p className="p-2">
              Meditex Healthcare Hub Limited is willing to partner with individals or organizations so as to deliver quality
              diagnositic results. Feel free to contact us using any of these mediums:
            </p>
            <div className="p-2"><i className="fa fa-volume-control-phone mr-1"></i>Phone: 08139203980 | 07035104804 | 07065048160 </div>
            <div className="p-2"><i className="fa fa-envelope mr-1"></i>Email: meditexdiagnosticlaboratory@gmail.com</div>
            <div className="p-2"><i className="fa fa-map-marker mr-1"></i> Addresses:</div>
            <div className="p-2" >
              Ikorodu Centre: 7, Dada Borokini Street, off Lagos Road, Ikorodu Lagos
            </div>
            <div className="p-2" >
              Ogijo(Sagamu) Centre: 12, MKO Abdulsalam close, off Oladapo Street, Jigaye, Ogijo Ogun State
            </div>
          </div>
        </div>
        <Toaster/>
      </>
    );
}

export default Contact