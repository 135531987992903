import React,{useState} from 'react'

import './index.css'


export default function ServiceCard({service,shouldTruncate}){

    const [readMore, setReadMore] = useState(false);

    const numberOfChars = 100
    const firstFewCharacters = service.description.substring(0, numberOfChars)

    const extraContent=<div>
        <p className="extra-content">
            {service.description.substring(numberOfChars, service.description.length)}
        </p>
    </div>

    const linkName=readMore?'Read Less << ':'Read More >> '

    return(
        <>
            <div className="rounded overflow-hidden service-card shadow-2xl">
                <img className="w-full h:15 sm:h-48" src={service.imgURL} alt={service.name}/>
                <div className="px-6 py-4">
                    <div className="font-bold text-xl md:text-lg mb-1">{service.name}</div>
                    {shouldTruncate ? 
                        <>
                            <p className="text-gray-700 text-base">
                                {firstFewCharacters}
                                {readMore && extraContent}
                            </p>
                            <div>
                                <a className="read-more-link" onClick={()=>{setReadMore(!readMore)}}><h2 className="purple-color text-white rounded">{linkName}</h2></a>
                            </div>
                        </>
                    :
                        <p className="text-gray-700 text-base">
                            {service.description}
                        </p>
                    }
                    
                </div>
                <div className="px-6 pt-4 pb-2">
                    {service.tags.map((tag,i)=>(
                        <span  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2" key={tag}>#{tag}</span>
                    ))}
                </div>
            </div>
        </>
    )
}